<template>
  <div class="wrapper">
    <div class="container">
      <div class="in-mail">
        <div class="header">
          <div style="display: flex;align-items: center;">
            <i class="icon icon-message"></i>
            <span class="title">站内信</span>
          </div>
          <div>
            <a-button type="primary" @click="read" >一键已读</a-button>
            <a-button type="danger" @click="delectRead"   style="margin-left: 10px">一键删除</a-button>
          </div>
        </div>
        <div class="mail-list">
          <div class="mail-box" v-for="(mail, i) in mailList" :key="i" @click="handleEnterMailDetail(mail)">
            <div style="display: flex;justify-content: space-between;">
              <div class="mail-box_top">
                <img class="mail-box_avatar" :src="mail.shareAvatar" alt="" />
                <div class="mail-box_title">
                  <div class="mail-box_name">{{ mail.nickName }}</div>
                  <div class="mail-box_date">{{ mail.createTime }}</div>
                </div>
              </div>
              <p class="title" style="margin-right: 10px;">
                <span v-if="mail.readed != 1" class="spot">·</span>
              </p>
            </div>
            <div class="mail-box_content">{{ mail.content }}</div>

            <div class="mail-box_right"></div>
          </div>
        </div>
        <div class="page-box">
          <a-pagination
            v-show="total >= 1"
            v-model:current="curPage"
            v-model:pageSize="pageSize"
            @showSizeChange="showSizeChange"
            @change="onChange"
            :total="total"
            show-size-changer
            show-quick-jumper
          >
          </a-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, ref} from 'vue';
import { useRouter } from 'vue-router';
import api from '@/services';
import { setunread, sharedDataStore } from '/src/store/sharedData';
import { message } from 'ant-design-vue';
export default {
  setup() {
    const router = useRouter();
    const mailList = ref([]);
    const state = reactive({
      curPage: 1,
      pageSize: 10,
      total: 0
    });

    const renderVNode = (_, { attrs: { vnode } }) => {
      return vnode;
    };

    const messageSharePage = async params => {
      try {
        let { data, success } = await api.messageSharePage(params);
        console.log(data, '======base');
        if (success) {
          state.total = data?.total;
          mailList.value = data?.records || null
        }
      } catch (error) {
        console.error('我的视频列表接口错误！');
      }
    };

    const read = async () => {
      const { data } = await api.readShare();
      setunread(0);
      messageSharePage({
        curPage: state.curPage,
        pageSize: state.pageSize
      });
      console.log(sharedDataStore.unread);
    };

    const delectRead = async () => {
      const { data } = await api.delectReadShare()
      messageSharePage({
        curPage: state.curPage,
        pageSize: state.pageSize
      });
    };

    const handleEnterMailDetail = async params => {
      await api.updateCode({
        id: params?.id,
        userId: params?.receiverId
      });
      // resourceType 1 直播 2视频
      if (params.resourceType == 1) {
        if (params.status == 2) {
          message.info('主播已经下播');
          messageSharePage({
            curPage: state.curPage,
            pageSize: state.pageSize
          });
          return false;
        }
        router.push({
          path: '/live-broadcast-watch',
          query: {
            pullFlow: params.pullLiveAddress,
            id: params.roomId,
            roomUserId: params.anchorId,
            liveType: params.liveType
          }
        });
      } else if(params.resourceType == 2){
        router.push({
          path: '/video-playback-watch',
          query: {
            pullFlow: params.pullLiveAddress,
            resourceId: params.id
          }
        });
      } else if(params.resourceType == 3){
        router.push({
          path: '/admin/machine-manage/uav-task-list',
        });
      }
    };

    onMounted(() => {
      messageSharePage({
        curPage: state.curPage,
        pageSize: state.pageSize
      });
    });

    const showSizeChange = (current, size) => {
      state.pageSize = size;
      messageSharePage({
        curPage: state.curPage,
        pageSize: state.pageSize
      });
    };
    const onChange = (page, pageSize) => {
      state.curPage = page;
      messageSharePage({
        curPage: state.curPage,
        pageSize: state.pageSize
      });
    };

    return {
      mailList,
      messageSharePage,
      handleEnterMailDetail,
      onChange,
      showSizeChange,
      ...toRefs(state),
      renderVNode,
      read,
      delectRead,
    };
  }
};
</script>

<style lang="less" scoped>
@import '../assets/less/public.less';
@icon_message: '~@imgs/icon_message.png';

.wrapper {
  position: relative;
  .background-content {
    position: absolute;
    width: 100%;
    height: 669px;
    z-index: -1;
    background: url('../assets/images/home/live-broadcast.png') no-repeat, linear-gradient(#256ebb 0%, #4180c9 100%);
    box-shadow: 0px 0px 10px 0px rgb(6 45 81 / 30%);
    background-size: 100% 80%;
    background-position: bottom;
  }
  .container {
    width: 1400px;
    margin: 0 auto;

    .in-mail {
      margin: 0 auto;
      padding: 30px;
      width: 1400px;
      background: #ffffff;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
      border-radius: 10px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon-message {
          width: 20px;
          height: 20px;
          display: block;
          background: url('@{icon_message}') no-repeat center / 100% 100%;
        }

        .title {
          margin-left: 8px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }

      .mail-list {
        margin-top: 20px;

        .mail-box {
          margin-bottom: 15px;
          padding: 10px 15px;
          width: 100%;
          background: #f6f6f6;
          border-radius: 10px;
          cursor: pointer;

          &_top {
            display: flex;
            align-items: center;
          }

          &_avatar {
            width: 30px;
            height: 30px;
            background: #007fff;
            border-radius: 24px;
            overflow: hidden;
          }

          &_title {
            margin-left: 10px;
          }

          &_name {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #007fff;
            line-height: 20px;
          }

          &_date {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
          }

          &_content {
            margin-top: 5px;
            padding-left: 40px;
            width: 982px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }
          &_right {
            float: right;
          }
        }
      }
    }
    .page-box {
      margin-top: 100px;
    }
    .spot {
      color: red;
      transform: scale(7);
      display: inline-block;
      position: relative;
      top: 18px;
    }
  }
}
</style>
